<template>
    <section class="content">
        <div class="container-fluid">
            <div id="_sectionFinance">
                <div class="info-box mb-3">
                    <div class="info-box-content">
                        <div class="row">
                            <div class="col-12 col-sm-4 mb-2 ml-auto">
                                <div class="input-group">
                                <input
                                type="text"
                                ref="daterange"
                                class="form-control"
                                style="border-right: 0"
                                />
                                <div class="input-group-append">
                                <div class="input-group-text" style="background-color: #fff">
                                    <span
                                    v-if="loading"
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                    ></span>
                                    <span v-if="isReady"><span class="fa fa-calendar"></span></span>
                                </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-12"></div>
                            <div class="col-12 col-sm-4 mb-2 ml-auto">
                                <select class="form-control" @change="kurirChange($event)">
                                    <option value="#" disabled selected>Pilih kurir</option>
                                    <option v-for="courier in totalCourier" v-bind:key="courier.courier_id" v-bind:value="courier.courier_id">
                                        {{ courier.nama_kurir }}
                                    </option>
                                    <option value="0">Semua</option>
                                </select>
                            </div>
                            <div class="col-12">
                                <h5 class="info-box-text"><strong>COD</strong></h5>
                                <div class="table-responsive mb-2">
                                <table class="table table-bordered">
                                    <thead></thead>
                                    <tbody>
                                    <tr>
                                        <td style="width: 85%">Total Nilai COD</td>
                                        <td style="width: 3%">Rp</td>
                                        <td style="width: 12%" id="nominal" v-bind:class="{ 'skeleton-item': loading4 }">
                                        <router-link
                                        :to="{
                                        name: 'Payment Shipper',
                                        params: {filter: { totalcod: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id}},
                                        }">
                                        {{ !dataFinance.total_cod ? 0 : formatMoney(dataFinance.total_cod) }}
                                        </router-link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 85%">Biaya COD</td>
                                        <td style="width: 3%">Rp</td>
                                        <td style="width: 12%" id="nominal" v-bind:class="{ 'skeleton-item': loading4 }">
                                        <router-link
                                        :to="{
                                        name: 'Payment Shipper',
                                        params: {filter: { biaya_cod: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id }},
                                        }">{{ !dataFinance.biaya_cod ? 0 : formatMoney(dataFinance.biaya_cod) }}
                                        </router-link>
                                        </td>
                                    </tr>
                                    <tr style="border-top-style: solid; border-width: 2px;">
                                        <th style="width: 85%;">Net Nilai COD</th>
                                        <td style="width: 3%">Rp</td>
                                        <th style="width: 12%" id="nominal" 
                                        v-bind:class="{ 'skeleton-item': loading4 }">{{ !dataFinance.net_cod ? 0 : formatMoney(dataFinance.net_cod) }}</th>
                                    </tr>
                                    </tbody>
                                </table>
                                </div>
    
                                <div class="table-responsive mb-2">
                                <table class="table table-bordered">
                                    <thead></thead>
                                    <tbody>
                                    <tr>
                                        <td style="width: 85%">COD Request Pickup</td>
                                        <td style="width: 3%">Rp</td>
                                        <td style="width: 12%" id="nominal" v-bind:class="{ 'skeleton-item': loading4 }">
                                        <router-link
                                        :to="{
                                        name: 'Payment Shipper',
                                        params: {filter: { req_pickup: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id}},
                                        }"> {{ !dataFinance.req_pickup ? 0 : formatMoney(dataFinance.req_pickup) }}
                                        </router-link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 85%">COD Dalam Proses</td>
                                        <td style="width: 3%">Rp</td>
                                        <td style="width: 12%" id="nominal" v-bind:class="{ 'skeleton-item': loading4 }">
                                        <router-link
                                        :to="{
                                        name: 'Payment Shipper',
                                        params: {filter: { totaldlmproses: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id}},
                                        }"> {{ !dataFinance.nilai_dlm_proses ? 0 : formatMoney(dataFinance.nilai_dlm_proses) }}
                                        </router-link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 85%">COD Retur</td>
                                        <td style="width: 3%">Rp</td>
                                        <td style="width: 12%" id="nominal" v-bind:class="{ 'skeleton-item': loading4 }">
                                        <router-link
                                        :to="{
                                        name: 'Payment Shipper',
                                        params: {filter: { cod_retur: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id }},
                                        }"> {{ !dataFinance.cod_retur ? 0 : formatMoney(dataFinance.cod_retur) }}
                                        </router-link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 85%">COD Terkirim</td>
                                        <td style="width: 3%">Rp</td>
                                        <td style="width: 12%" id="nominal" v-bind:class="{ 'skeleton-item': loading4 }">
                                        <router-link
                                        :to="{
                                        name: 'Payment Shipper',
                                        params: {filter: { cod_terkirim: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id }},
                                        }"> {{ !dataFinance.cod_terkirim ? 0 : formatMoney(dataFinance.cod_terkirim) }}
                                        </router-link>
                                        </td>
                                    </tr>
                                    <tr style="border-top-style: solid; border-width: 2px;">
                                        <th style="width: 85%;">Net Nilai COD</th>
                                        <td style="width: 3%">Rp</td>
                                        <th style="width: 12%" id="nominal" 
                                        v-bind:class="{ 'skeleton-item': loading4 }"> {{ !dataFinance.net_cod2 ? 0 : formatMoney(dataFinance.net_cod2) }}
                                        </th>
                                    </tr>
                                    </tbody>
                                </table>
                                </div>
    
                                <div class="table-responsive mb-2">
                                <table class="table table-bordered">
                                    <thead></thead>
                                    <tbody>
                                    <tr>
                                        <td style="width: 85%">COD Terbayar</td>
                                        <td style="width: 3%">Rp</td>
                                        <td style="width: 12%" id="nominal" v-bind:class="{ 'skeleton-item': loading4 }">
                                        <router-link
                                        :to="{
                                        name: 'Payment Shipper',
                                        params: {filter: { totalterbayar: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id }},
                                        }"> {{ !dataFinance.cod_terbayar ? 0 : formatMoney(dataFinance.cod_terbayar) }}
                                        </router-link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 85%">COD Belum Terbayar</td>
                                        <td style="width: 3%">Rp</td>
                                        <td style="width: 12%" id="nominal" v-bind:class="{ 'skeleton-item': loading4 }">
                                        <router-link
                                        :to="{
                                        name: 'Payment Shipper',
                                        params: {filter: { belumterbayar: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id }},
                                        }"> {{ !dataFinance.cod_belum_terbayar ? 0 : formatMoney(dataFinance.cod_belum_terbayar) }}
                                        </router-link>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            <div class="col-12">
                                <h5 class="info-box-text"><strong>NON COD</strong></h5>
                                
                                <div class="table-responsive mb-2">
                                <table class="table table-bordered">
                                    <thead></thead>
                                    <tbody>
                                    <tr>
                                        <td style="width: 85%">Ongkir Reguler</td>
                                        <td style="width: 3%">Rp</td>
                                        <td style="width: 12%" id="nominal" v-bind:class="{ 'skeleton-item': loading4 }">
                                        <router-link
                                        :to="{
                                        name: 'Payment Shipper',
                                        params: {filter: { ongkirreg: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id }},
                                        }"> {{ !dataFinance.ongkir_reguler ? 0 : formatMoney(dataFinance.ongkir_reguler) }}
                                        </router-link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 85%; color: white; border-color: white;">COD Belum Terbayar</td>
                                        <td style="width: 3%; color: white; border-color: white;">Rp</td>
                                        <td style="width: 12%; border-color: white;" id="nominal">
                                        <p to="" style="color: white;">
                                            {{ formatMoney(dataFinance.cod_belum_terbayar) }}
                                        </p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </template>
    <script>
    import Daterangepicker from "daterangepicker";
    import $ from "jquery";
    import Chart from "chart.js";
    import { authFetch, auth, formatCurrency, isMobile } from "@/libs/hxcore";
    import moment from "moment";
    import Vue from 'vue';
    import "daterangepicker/daterangepicker.css";
    export default {
        name: "Home",
        data: function () {
            return {
                showModalDashboard: false,
                isShipper: false,
                roles: "",
                dateStart: moment().day(-31).format("YYYY-MM-DD"),
                dateEnd: moment().add(2, 'd').format("YYYY-MM-DD"),
                dataFinance: {},
                totalCourier: [],
                courier_id: '',
                url: window.$apiUrl,
                dt: {
                    start: "",
                    end: "",
                },
                infoBar: [],
                loading: false,
                loading4: false,
            };
        },
        components: {
        },
        created: function () {
            this.roles = this.$route.params.roles;
            var info = auth.user();
            if (info) {
              this.username = info.display_name;
              this.isShipper = info.role_id > 10;
            }
    
            if(Vue.config.appHost == 'xpress')
                this.check = false;
            else this.check = true;
    
            authFetch("/master/courier/groups")
                .then((res) => {
                    if (res.status === 201) {} else if (res.status === 400) {}
                    return res.json();
                })
                .then((js) => {
                    this.totalCourier = js.data;
                });

            this.loadStatusFinance();
        },
        computed: {
            isReady: function () {
              return !this.loading;
            },
            isMobile: function(){return isMobile();},
        },
        methods: {
            // handleScroll: ,
            kurirChange(event) {
                let id = event.target.value;
                this.courier_id = id;
                this.loadedFinance = false;
                this.loadStatusFinance();
            },
            loadStatusFinance: function () {
                if (this.loadedFinance == true) {
                    return;
                } else {
                    this.loadedFinance = true;
                    var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id?this.courier_id:'' };
                    this.loading4 = true;
                    var search = new URLSearchParams(params);
                    authFetch(`/dashboard/financial?${search}`).then((res) => {
                        res.json().then((json) => {
                            this.loading4 = false;
                            if (json.success) {
                                this.dataFinance = json.data;
                            }
                            $('.loading-overlay').removeClass('show');
                        });
                    });
                }
            },
            formatMoney(val) {
                return formatCurrency(val);
            },
        },
        mounted() {
            let self = this;
    
            //daterange all
            new Daterangepicker(
                this.$refs.daterange,
                {
                    startDate: moment().day(-31),
                    endDate: moment().add(2, 'd'),
                    locale: {
                        format: 'DD/MM/YYYY'
                    },
                },
                function (d1, d2) {
                    self.dateStart = d1.format("YYYY-MM-DD");
                    self.dateEnd = d2.format("YYYY-MM-DD");
                    self.loadedFinance = false;
                    self.loadStatusFinance();
                }
            );

            // window.addEventListener('scroll', function (event) {
            //     // const self = this;
            //     if ($('#_sectionTicket').length == 0) {
            //         return;
            //     }
            //         hT4 = $('#_sectionFinance').offset().top,
            //         hH4 = $('#_sectionFinance').outerHeight(),
            //         wH = $(window).height(),
            //         wS = $(this).scrollTop();
    
            //     // console.log(hT2, hH2, wH, wS);
            //     if (wS > (hT4+hH4-wH)){
            //         //load finance report
            //         self.loadStatusFinance();
            //     }
            // });
            // $(window).scroll();
        },
    };
    </script>
    <style>
    #nominal{
      text-align: right;
    }
    
    router-link {
      background-color: black;
      color: black;
    }
    
    a, a:hover {
      color: black;
    }
    
    .info-box .info-box-text {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
    }
    
    .info-text{
        color: initial;
        padding: 3px;
    }
    </style>