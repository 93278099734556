var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{attrs:{"id":"_sectionFinance"}},[_c('div',{staticClass:"info-box mb-3"},[_c('div',{staticClass:"info-box-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-4 mb-2 ml-auto"},[_c('div',{staticClass:"input-group"},[_c('input',{ref:"daterange",staticClass:"form-control",staticStyle:{"border-right":"0"},attrs:{"type":"text"}}),_c('div',{staticClass:"input-group-append"},[_c('div',{staticClass:"input-group-text",staticStyle:{"background-color":"#fff"}},[(_vm.loading)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),(_vm.isReady)?_c('span',[_c('span',{staticClass:"fa fa-calendar"})]):_vm._e()])])])]),_c('div',{staticClass:"col-12"}),_c('div',{staticClass:"col-12 col-sm-4 mb-2 ml-auto"},[_c('select',{staticClass:"form-control",on:{"change":function($event){return _vm.kurirChange($event)}}},[_c('option',{attrs:{"value":"#","disabled":"","selected":""}},[_vm._v("Pilih kurir")]),_vm._l((_vm.totalCourier),function(courier){return _c('option',{key:courier.courier_id,domProps:{"value":courier.courier_id}},[_vm._v(" "+_vm._s(courier.nama_kurir)+" ")])}),_c('option',{attrs:{"value":"0"}},[_vm._v("Semua")])],2)]),_c('div',{staticClass:"col-12"},[_vm._m(0),_c('div',{staticClass:"table-responsive mb-2"},[_c('table',{staticClass:"table table-bordered"},[_c('thead'),_c('tbody',[_c('tr',[_c('td',{staticStyle:{"width":"85%"}},[_vm._v("Total Nilai COD")]),_c('td',{staticStyle:{"width":"3%"}},[_vm._v("Rp")]),_c('td',{class:{ 'skeleton-item': _vm.loading4 },staticStyle:{"width":"12%"},attrs:{"id":"nominal"}},[_c('router-link',{attrs:{"to":{
                                    name: 'Payment Shipper',
                                    params: {filter: { totalcod: 1, dt1: _vm.dateStart, dt2: _vm.dateEnd, courier_id: _vm.courier_id}},
                                    }}},[_vm._v(" "+_vm._s(!_vm.dataFinance.total_cod ? 0 : _vm.formatMoney(_vm.dataFinance.total_cod))+" ")])],1)]),_c('tr',[_c('td',{staticStyle:{"width":"85%"}},[_vm._v("Biaya COD")]),_c('td',{staticStyle:{"width":"3%"}},[_vm._v("Rp")]),_c('td',{class:{ 'skeleton-item': _vm.loading4 },staticStyle:{"width":"12%"},attrs:{"id":"nominal"}},[_c('router-link',{attrs:{"to":{
                                    name: 'Payment Shipper',
                                    params: {filter: { biaya_cod: 1, dt1: _vm.dateStart, dt2: _vm.dateEnd, courier_id: _vm.courier_id }},
                                    }}},[_vm._v(_vm._s(!_vm.dataFinance.biaya_cod ? 0 : _vm.formatMoney(_vm.dataFinance.biaya_cod))+" ")])],1)]),_c('tr',{staticStyle:{"border-top-style":"solid","border-width":"2px"}},[_c('th',{staticStyle:{"width":"85%"}},[_vm._v("Net Nilai COD")]),_c('td',{staticStyle:{"width":"3%"}},[_vm._v("Rp")]),_c('th',{class:{ 'skeleton-item': _vm.loading4 },staticStyle:{"width":"12%"},attrs:{"id":"nominal"}},[_vm._v(_vm._s(!_vm.dataFinance.net_cod ? 0 : _vm.formatMoney(_vm.dataFinance.net_cod)))])])])])]),_c('div',{staticClass:"table-responsive mb-2"},[_c('table',{staticClass:"table table-bordered"},[_c('thead'),_c('tbody',[_c('tr',[_c('td',{staticStyle:{"width":"85%"}},[_vm._v("COD Request Pickup")]),_c('td',{staticStyle:{"width":"3%"}},[_vm._v("Rp")]),_c('td',{class:{ 'skeleton-item': _vm.loading4 },staticStyle:{"width":"12%"},attrs:{"id":"nominal"}},[_c('router-link',{attrs:{"to":{
                                    name: 'Payment Shipper',
                                    params: {filter: { req_pickup: 1, dt1: _vm.dateStart, dt2: _vm.dateEnd, courier_id: _vm.courier_id}},
                                    }}},[_vm._v(" "+_vm._s(!_vm.dataFinance.req_pickup ? 0 : _vm.formatMoney(_vm.dataFinance.req_pickup))+" ")])],1)]),_c('tr',[_c('td',{staticStyle:{"width":"85%"}},[_vm._v("COD Dalam Proses")]),_c('td',{staticStyle:{"width":"3%"}},[_vm._v("Rp")]),_c('td',{class:{ 'skeleton-item': _vm.loading4 },staticStyle:{"width":"12%"},attrs:{"id":"nominal"}},[_c('router-link',{attrs:{"to":{
                                    name: 'Payment Shipper',
                                    params: {filter: { totaldlmproses: 1, dt1: _vm.dateStart, dt2: _vm.dateEnd, courier_id: _vm.courier_id}},
                                    }}},[_vm._v(" "+_vm._s(!_vm.dataFinance.nilai_dlm_proses ? 0 : _vm.formatMoney(_vm.dataFinance.nilai_dlm_proses))+" ")])],1)]),_c('tr',[_c('td',{staticStyle:{"width":"85%"}},[_vm._v("COD Retur")]),_c('td',{staticStyle:{"width":"3%"}},[_vm._v("Rp")]),_c('td',{class:{ 'skeleton-item': _vm.loading4 },staticStyle:{"width":"12%"},attrs:{"id":"nominal"}},[_c('router-link',{attrs:{"to":{
                                    name: 'Payment Shipper',
                                    params: {filter: { cod_retur: 1, dt1: _vm.dateStart, dt2: _vm.dateEnd, courier_id: _vm.courier_id }},
                                    }}},[_vm._v(" "+_vm._s(!_vm.dataFinance.cod_retur ? 0 : _vm.formatMoney(_vm.dataFinance.cod_retur))+" ")])],1)]),_c('tr',[_c('td',{staticStyle:{"width":"85%"}},[_vm._v("COD Terkirim")]),_c('td',{staticStyle:{"width":"3%"}},[_vm._v("Rp")]),_c('td',{class:{ 'skeleton-item': _vm.loading4 },staticStyle:{"width":"12%"},attrs:{"id":"nominal"}},[_c('router-link',{attrs:{"to":{
                                    name: 'Payment Shipper',
                                    params: {filter: { cod_terkirim: 1, dt1: _vm.dateStart, dt2: _vm.dateEnd, courier_id: _vm.courier_id }},
                                    }}},[_vm._v(" "+_vm._s(!_vm.dataFinance.cod_terkirim ? 0 : _vm.formatMoney(_vm.dataFinance.cod_terkirim))+" ")])],1)]),_c('tr',{staticStyle:{"border-top-style":"solid","border-width":"2px"}},[_c('th',{staticStyle:{"width":"85%"}},[_vm._v("Net Nilai COD")]),_c('td',{staticStyle:{"width":"3%"}},[_vm._v("Rp")]),_c('th',{class:{ 'skeleton-item': _vm.loading4 },staticStyle:{"width":"12%"},attrs:{"id":"nominal"}},[_vm._v(" "+_vm._s(!_vm.dataFinance.net_cod2 ? 0 : _vm.formatMoney(_vm.dataFinance.net_cod2))+" ")])])])])]),_c('div',{staticClass:"table-responsive mb-2"},[_c('table',{staticClass:"table table-bordered"},[_c('thead'),_c('tbody',[_c('tr',[_c('td',{staticStyle:{"width":"85%"}},[_vm._v("COD Terbayar")]),_c('td',{staticStyle:{"width":"3%"}},[_vm._v("Rp")]),_c('td',{class:{ 'skeleton-item': _vm.loading4 },staticStyle:{"width":"12%"},attrs:{"id":"nominal"}},[_c('router-link',{attrs:{"to":{
                                    name: 'Payment Shipper',
                                    params: {filter: { totalterbayar: 1, dt1: _vm.dateStart, dt2: _vm.dateEnd, courier_id: _vm.courier_id }},
                                    }}},[_vm._v(" "+_vm._s(!_vm.dataFinance.cod_terbayar ? 0 : _vm.formatMoney(_vm.dataFinance.cod_terbayar))+" ")])],1)]),_c('tr',[_c('td',{staticStyle:{"width":"85%"}},[_vm._v("COD Belum Terbayar")]),_c('td',{staticStyle:{"width":"3%"}},[_vm._v("Rp")]),_c('td',{class:{ 'skeleton-item': _vm.loading4 },staticStyle:{"width":"12%"},attrs:{"id":"nominal"}},[_c('router-link',{attrs:{"to":{
                                    name: 'Payment Shipper',
                                    params: {filter: { belumterbayar: 1, dt1: _vm.dateStart, dt2: _vm.dateEnd, courier_id: _vm.courier_id }},
                                    }}},[_vm._v(" "+_vm._s(!_vm.dataFinance.cod_belum_terbayar ? 0 : _vm.formatMoney(_vm.dataFinance.cod_belum_terbayar))+" ")])],1)])])])])]),_c('div',{staticClass:"col-12"},[_vm._m(1),_c('div',{staticClass:"table-responsive mb-2"},[_c('table',{staticClass:"table table-bordered"},[_c('thead'),_c('tbody',[_c('tr',[_c('td',{staticStyle:{"width":"85%"}},[_vm._v("Ongkir Reguler")]),_c('td',{staticStyle:{"width":"3%"}},[_vm._v("Rp")]),_c('td',{class:{ 'skeleton-item': _vm.loading4 },staticStyle:{"width":"12%"},attrs:{"id":"nominal"}},[_c('router-link',{attrs:{"to":{
                                    name: 'Payment Shipper',
                                    params: {filter: { ongkirreg: 1, dt1: _vm.dateStart, dt2: _vm.dateEnd, courier_id: _vm.courier_id }},
                                    }}},[_vm._v(" "+_vm._s(!_vm.dataFinance.ongkir_reguler ? 0 : _vm.formatMoney(_vm.dataFinance.ongkir_reguler))+" ")])],1)]),_c('tr',[_c('td',{staticStyle:{"width":"85%","color":"white","border-color":"white"}},[_vm._v("COD Belum Terbayar")]),_c('td',{staticStyle:{"width":"3%","color":"white","border-color":"white"}},[_vm._v("Rp")]),_c('td',{staticStyle:{"width":"12%","border-color":"white"},attrs:{"id":"nominal"}},[_c('p',{staticStyle:{"color":"white"},attrs:{"to":""}},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.dataFinance.cod_belum_terbayar))+" ")])])])])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"info-box-text"},[_c('strong',[_vm._v("COD")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"info-box-text"},[_c('strong',[_vm._v("NON COD")])])
}]

export { render, staticRenderFns }